import { useMemo, useState } from 'react'

import {
    DatePicker,
    IconHelpCircle,
    Input,
    Select,
    Tooltip,
    Button,
    Popup,
    IconAlertCircle,
    HandleMSIDuplicatesPopup,
    FormErrorsType
} from '@/components'
import { InverterManufacturerEnum } from '@/enums'
import { InverterManufacturer, Warranty, WarrantyProductType } from '@/models'
import { IdType, WarrantyDataType, FormChangeEventHandler, JsonDataType } from '@/types'

export interface InverterFormDataType extends JsonDataType {
    inverter_name: string
    inverter_manufacturer: InverterManufacturerEnum | string
    num_microinverters: number | string
    install_date: Date | null
    system_id: IdType
}

type InverterFormProps = {
    form: InverterFormDataType
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    inputClassName?: string
    required?: boolean
    productType: WarrantyProductType
    warranty?: Warranty
}

export const InverterForm = ({
    form,
    inputClassName,
    errors,
    required,
    warranty,
    productType,
    ...props
}: InverterFormProps) => {
    const [showSystemIdGuide, setShowSystemIdGuide] = useState(false)
    const [isDuplicateMSIPopupOpen, setIsDuplicateMSIPopupOpen] = useState(false)

    const manufacturers = useMemo(() => {
        const additional = []
        if (form.inverter_manufacturer === InverterManufacturerEnum.OTHER) {
            additional.push(InverterManufacturer.other)
        }
        return [...InverterManufacturer.allKnown.filter(item => !item.isAlternative), ...additional]
    }, [])

    const inverterManufacturer = useMemo(() =>
        InverterManufacturer.find(form.inverter_manufacturer as InverterManufacturerEnum), [form])

    const numMicroinvertersLabel = useMemo(() => {
        if (inverterManufacturer.useOptimizers) return 'Number of Optimizers'
        if (inverterManufacturer.useMicroInverters) return 'Number of Microinverters'
        return 'Number of Microinverters/Optimizers'
    }, [inverterManufacturer])

    const msiIcon = useMemo(() => !errors.system_id_conflict
        ? <Tooltip content={<div className="flex flex-col gap-4 max-w-xs">
            <p className="font-semibold">Manufacturer System ID</p>
            <p>
                It is a unique identifier assigned by your solar system
                manufacturer. To find it, log into your manufacturer&apos;s
                monitoring portal. They typically provide this ID as
                part of your system details.
            </p>
            <p>This ID helps us connect your site to Solar Insure Monitoring.</p>
        </div>}>
            <IconHelpCircle className="stroke-gray-500" size="sm"/>
        </Tooltip>
        : <button
            className="cursor-pointer"
            type="button"
            onClick={() => {
                setIsDuplicateMSIPopupOpen(true)
            }}
        >
            <IconAlertCircle className="stroke-error-500"/>
        </button>
    , [errors.system_id_conflict])

    return <>
        <Input
            id="inverter-name"
            name="inverter_name"
            label={`Inverter Model${required ? '*' : ''}`}
            placeholder="Inverter Model"
            className={inputClassName}
            onChange={props.onChange}
            value={form.inverter_name}
            errors={errors.inverter_name}
        />

        <div className="grid lg:grid-cols-2 gap-5">
            <Select
                id="inverter-manufacturer"
                name="inverter_manufacturer"
                label={`Inverter Manufacturer${required ? '*' : ''}`}
                options={[
                    ...warranty ? [] : [{
                        value: '',
                        title: 'Inverter Manufacturer'
                    }],
                    ...manufacturers.map(item => ({
                        value: item.key,
                        title: item.title
                    }))
                ]}
                className={inputClassName}
                onChange={props.onChange}
                value={form.inverter_manufacturer}
                errors={errors.inverter_manufacturer}
            />

            <Input
                id="num_microinverters"
                name="num_microinverters"
                label={numMicroinvertersLabel}
                placeholder={numMicroinvertersLabel}
                className={inputClassName}
                onChange={props.onChange}
                value={form.num_microinverters}
                errors={errors.num_microinverters}
                disabled={!form.inverter_manufacturer}
                mask={{
                    numeral: true,
                    numeralIntegerScale: 3,
                    numeralDecimalScale: 0,
                    numeralPositiveOnly: true,
                    numeralThousandsGroupStyle: 'none'
                }}
                postIcon={
                    <Tooltip content={<>
                        <p><strong>Number of Optimizers/Microinverters</strong> (optional)</p><br/>
                        <p>
                            For String Inverter systems (usually with SNA, Fronius, SolarEdge, or Generac Inverters)
                            Solar Insure likes to collect information on the number of optimizers.
                        </p>
                        <br/>
                        <p>
                            For Micro inverter systems (usually with Enphase, Hoymiles, Generac, NEP, or APS inverters)
                            Solar Insure likes to collect information on the number of microinverters present to
                            understand the panel to micro ratio.
                        </p>
                        <br/>
                        <p>
                            Collecting these values helps Solar Insure to more accurately calculate and monitor expected
                            production ranges for a particular solar system.
                        </p>
                    </>}>
                        <IconHelpCircle className="stroke-gray-500" size="sm"/>
                    </Tooltip>
                }
            />
        </div>

        {productType.hasPowerProduction && <DatePicker
            id="install-date"
            name="install_date"
            label="Installation Date*"
            placeholder="Installation Date"
            className={inputClassName}
            onChange={props.onChange}
            value={form.install_date}
            errors={errors.install_date}
            options={{
                minDate: warranty?.created_at
                    ? warranty.created_at?.clone().subtract('year', 1)
                    : new Date().subtract('year', 1)
            }}
        />}

        <Input
            id="system_id"
            name="system_id"
            label="Manufacturer System Identifier"
            placeholder="Manufacturer System Identifier"
            className={inputClassName}
            onChange={props.onChange}
            value={form.system_id}
            errors={errors.system_id}
            trim
            hint={!!inverterManufacturer.systemIdGuide &&
                <Button
                    design="btn-link"
                    type="button"
                    className="!text-primary-700"
                    onClick={() => setShowSystemIdGuide(true)}
                >
                    How to Find Manufacturer System ID
                </Button>}
            postIcon={msiIcon}
        />

        {inverterManufacturer && <Popup open={showSystemIdGuide} onClose={() => setShowSystemIdGuide(false)}>
            <h3 className="flex gap-3 items-center text-xl font-semibold">
                {inverterManufacturer.getLogo()}
                How to Find System ID
            </h3>

            <div className="p-4 mt-2 text-sm text-gray-750">
                {inverterManufacturer.systemIdGuide}
            </div>
        </Popup>}

        {isDuplicateMSIPopupOpen && <HandleMSIDuplicatesPopup
            isOpen={isDuplicateMSIPopupOpen}
            onClose={() => setIsDuplicateMSIPopupOpen(false)}
            warranty={new Warranty({
                type: form.type,
                inverter: {
                    model_name: form.inverter_name,
                    provider: form.inverter_manufacturer
                },
                panel: { model_name: form.panel_name },
                size_kw: form.size_kw,
                homeowner: {
                    company_id: form.company_id,
                    street_address: form.street_address,
                    city: form.city,
                    state: form.state,
                    zip: form.zip,
                    first_name: form.first_name,
                    last_name: form.last_name,
                    email: form.email,
                    phone: form.phone,
                    install_date: form.install_date ? form.install_date.toISODate() : null,
                    system_id: form.system_id
                }
            } as unknown as WarrantyDataType)}
        />}
    </>
}
