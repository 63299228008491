import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import {
    PageHeader,
    WarrantyIndexButtons,
    WarrantyIndexFilters,
    Tooltip,
    IconHelpCircle, WarrantyIndexTablePlaceholder
} from '@/components'
import {
    AuthLayout,
    IndexContainer
} from '@/containers'
import { useAuth, useIndexContainer } from '@/hooks'
import { Company, Model, Warranty } from '@/models'
import {
    DateRangeType,
    IndexContainerChangeEvent,
    IndexContainerFilterValueType,
    WarrantyIndexDataType,
    WarrantyIndexFiltersType
} from '@/types'

const WARRANTY_INDEX_FILTERS: WarrantyIndexFiltersType = {
    search: {
        label: 'Search',
        defaultValue: '',
        value: '',
        isStandalone: true
    },
    created_at_range: {
        label: 'Submission Date',
        defaultValue: [null, null],
        value: [null, null],
        isStandalone: true,
        convert: (value: IndexContainerFilterValueType) =>
            value ? (value as [string, string]).map(Model.toDate) as DateRangeType : [null, null]
    },
    statuses: {
        label: 'Status',
        defaultValue: [],
        value: [],
        isStandalone: true,
        isMultiple: true
    },
    state: {
        label: 'State',
        defaultValue: '',
        value: ''
    },
    manufacturers: {
        label: 'Manufacturer',
        defaultValue: [],
        value: [],
        isMultiple: true
    },
    types: {
        label: 'Warranty Type',
        defaultValue: [],
        value: [],
        isMultiple: true
    },
    company_id: {
        label: 'Company',
        defaultValue: '',
        value: ''
    },
    affiliate_id: {
        label: 'Affiliate',
        defaultValue: '',
        value: ''
    },
    company_account_activity_statuses: {
        label: 'Company Status',
        defaultValue: [],
        value: [],
        isMultiple: true
    },
    account_manager_email: {
        label: 'Account Manager',
        defaultValue: '',
        value: ''
    },
    install_date_range: {
        label: 'Installation Date',
        defaultValue: [null, null],
        value: [null, null],
        convert: (value: IndexContainerFilterValueType) =>
            value ? (value as [string, string]).map(Model.toDate) as DateRangeType : [null, null]
    },
    with_pto_date: {
        label: 'PTO Date',
        defaultValue: '',
        value: ''
    },
    pto_date_range: {
        label: 'PTO Date',
        defaultValue: [null, null],
        value: [null, null],
        convert: (value: IndexContainerFilterValueType) =>
            value ? (value as [string, string]).map(Model.toDate) as DateRangeType : [null, null]
    },
    with_emailed_at: {
        label: 'Emailed Date',
        defaultValue: '',
        value: ''
    },
    emailed_at_range: {
        label: 'Emailed Date',
        defaultValue: [null, null],
        value: [null, null],
        convert: (value: IndexContainerFilterValueType) =>
            value ? (value as [string, string]).map(Model.toDate) as DateRangeType : [null, null]
    },
    battery_product_id: {
        label: 'Battery Make',
        defaultValue: '',
        value: ''
    },
    battery_size_kw_range: {
        label: 'Battery Size(kW)',
        defaultValue: [0, 1000],
        value: [0, 1000],
        convert: (value: IndexContainerFilterValueType) =>
            value ? (value as [string, string]).map(Number) as [number, number] : [0, 1000]
    },
    size_kw_range: {
        label: 'System Size(kW)',
        defaultValue: [0, 1000],
        value: [0, 1000],
        convert: (value: IndexContainerFilterValueType) =>
            value ? (value as [string, string]).map(Number) as [number, number] : [0, 1000]
    }
}

export const WarrantiesIndex = () => {
    const {
        filters,
        handleFiltersChange
    } = useIndexContainer(WARRANTY_INDEX_FILTERS)
    const navigate = useNavigate()
    const auth = useAuth()

    const [reload, setReload] = useState(false)
    const [data, setData] = useState<WarrantyIndexDataType>({
        meta: {
            total: 0,
            overall_count: 0,
            per_page: 0,
            current_page: 0,
            warranty_status_list: []
        },
        rows: [],
        selectedRows: [],
        excludedRows: []
    })

    useEffect(() => {
        setData(prevData => ({ ...prevData, selectedRows: [] }))
    }, [filters])

    const handleDataChange = (e: IndexContainerChangeEvent) => {
        setData(data => ({ ...data, [e.name]: e.value }))
    }

    const rowsMemo = useMemo(() => data.rows.map(item => ({
        id: item.id,
        type: <div className="flex items-center gap-2 text-xs font-brand font-semibold">
            {item.type.brandIcon('sm', true)}
            {item.type.title}
        </div>,
        'company.name': item.company?.name,
        'homeowner.created_at': item.created_at?.format() || '',
        customer_info: <div className="flex flex-col items-start gap-1">
            {item.customer_info?.full_name}
            {item.status.badge()}
        </div>,
        city_state: item.install_address ? `${item.install_address.city}, ${item.install_address.state}` : '',
        'homeowner.street_address': item.install_address ? <>
            <div className="text-gray-900">{item.install_address.street_address}</div>
            <div className="text-gray-500">{item.install_address.city}, {item.install_address.state} {item.install_address.zip}</div>
        </> : '',
        unique_id: item.unique_id,
        'warranties.size_w': Warranty.getField('size_kw').format(item.size_kw),
        'warranties.sent_at': item.sent_date?.format(),
        'homeowner.install_date': item.install_date?.format(),
        'warranties.policy_num': item.policy_num,
        'panel.model_name': item.panel_model,
        'inverter.model_name': item.inverter_model,
        'warranties.eligible_commission': item.eligible_commission ? item.eligible_commission.money() : '',
        manufacturer: item.manufacturer.title,
        _path: `/warranties/${item.id}/warranty`
    })), [data.rows])

    return <AuthLayout heading={<PageHeader title="Warranties"/>}>
        <IndexContainer
            id="warranties"
            dataName="Warranties"
            api={(...args) => Warranty.index(...args)}
            data={data}
            onDataChange={handleDataChange}
            filters={filters}
            onFiltersChange={handleFiltersChange}
            onFiltersClear={() => navigate('/warranties')}
            reload={reload}
            onReloadChange={setReload}
            tableOptions={{
                dataName: 'Warranties',
                infinity: true,
                selectable: true,
                maxColumnsAmount: 6
            }}
            columns={[
                { title: 'Type', field: 'type' },
                { title: 'Contractor', field: 'company.name' },
                { title: 'Submission Date', field: 'homeowner.created_at' },
                { title: 'Homeowner Name', field: 'customer_info' },
                { title: 'City, State', field: 'city_state' },
                { title: 'Address', field: 'homeowner.street_address' },
                { title: 'Salesforce Unique ID', field: 'unique_id', showIf: auth.user?.role.isAdminOrStaffOrAdvisor },
                { title: 'System Size', field: 'warranties.size_w' },
                { title: 'Emailed Date', field: 'warranties.sent_at' },
                { title: 'Install Date', field: 'homeowner.install_date' },
                { title: 'Policy Number', field: 'warranties.policy_num' },
                { title: 'Panel Model', field: 'panel.model_name' },
                { title: 'Inverter Model', field: 'inverter.model_name' },
                {
                    title: <>
                        <Tooltip
                            interactive
                            hideOnClick
                            content={Company.eligibleCommissionTooltipContent}
                            className="flex gap-1 items-center"
                        >
                            Eligible Commission
                            <IconHelpCircle className="stroke-gray-400" size="sm"/>
                        </Tooltip>
                    </>,
                    title_raw: 'Eligible Commission',
                    field: 'warranties.eligible_commission',
                    showIf: auth.user?.role.isAdminOrStaffOrAdvisor
                },
                { title: 'Manufacturer', field: 'manufacturer' }
            ]}
            rows={rowsMemo}
            placeholder={data.meta.total === 0 && !auth.user?.role.isAdvisor &&
                <WarrantyIndexTablePlaceholder/>}
            leftSide={<WarrantyIndexFilters
                standalone
                filters={filters}
                onChange={handleFiltersChange}
                meta={data.meta}
            />}
            rightSide={<WarrantyIndexButtons
                data={data}
                onChange={() => setReload(true)}
                data-test="warranties-index"
            />}
            popupContent={<WarrantyIndexFilters
                filters={filters}
                onChange={handleFiltersChange}
            />}
        />
    </AuthLayout>
}
