import classnames from 'classnames'

import {
    BatteriesForm, BatteriesFormDataType,
    Button, FormErrorsType,
    InverterForm, InverterFormDataType,
    PanelForm, PanelFormDataType
} from '@/components'
import { WarrantyProductType } from '@/models'
import { FormChangeEventHandler } from '@/types'

export interface CreateWarrantySystemFormDataType extends
    PanelFormDataType, InverterFormDataType, BatteriesFormDataType {}

type WarrantyCreateSystemStepProps = {
    form: CreateWarrantySystemFormDataType
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    onPrevious: () => void
    processing: boolean
    warrantyProductType: WarrantyProductType
}
export const WarrantyCreateSystemStep = ({
    form,
    errors,
    processing,
    warrantyProductType,
    ...props
}: WarrantyCreateSystemStepProps) =>
    <div className="grid lg:grid-cols-2 gap-3 lg:gap-6 mt-6 lg:mt-6" data-test="warranty-create-step-2">
        {warrantyProductType.hasPowerProduction && <>
            <h3 className="font-semibold lg:col-span-2">Panel Information</h3>
            <PanelForm form={form} errors={errors} onChange={props.onChange}/>
            <hr className="lg:col-span-2 border-gray-200"/>
        </>}

        <div className="lg:col-span-2">
            <h3 className="font-semibold">
                Inverter Information {!warrantyProductType.hasPowerProduction &&
                <span className="font-normal italic">
                    (Optional - Standalone Inverters Not Covered Under Warranty)
                </span>}
            </h3>
            {!warrantyProductType.hasPowerProduction && <p className="text-xs text-gray-500 mt-1 leading-6">
                Providing inverter details
                is <span className="font-semibold">optional</span> and <span className="font-semibold">not
                required</span> for <span className="capitalize">{warrantyProductType.title}</span> warranty
                registration, as <span className="font-semibold">standalone inverters are not covered</span> under this
                warranty type. We ask only because if your <span className="font-semibold">battery is
                connected to a separate standalone inverter</span>,
                we <span className="font-semibold">may</span> be able to
                retrieve <span className="font-semibold">basic battery monitoring data</span>  to help
                expedite claims processing. Solar Insure integrates with select inverters to pull system
                data, but monitoring is limited to certain brands (listed on the Monitoring tab in
                Daybreak) and is not guaranteed for all manufacturers on our AVL.
            </p>}
        </div>
        <InverterForm
            form={form}
            errors={errors}
            onChange={props.onChange}
            required={warrantyProductType.hasPowerProduction}
            productType={warrantyProductType}
        />
        <hr className="lg:col-span-2 border-gray-200"/>

        {warrantyProductType.hasStorage && <div className={classnames(
            'grid lg:grid-cols-2 gap-3 lg:gap-6 lg:col-span-2',
            { 'order-first': !warrantyProductType.hasPowerProduction }
        )}>
            <h3 className="lg:col-span-2 font-semibold">
                {warrantyProductType.coverageTitle}
            </h3>
            <BatteriesForm
                form={form}
                errors={errors}
                onChange={props.onChange}
                productType={warrantyProductType}
                className="lg:col-span-2"
            />
            <hr className="lg:col-span-2 border-gray-200"/>
        </div>}

        <div className="flex flex-col xl:flex-row gap-3 justify-between lg:col-span-2">
            <p className="text-sm">
                <Button
                    design="btn-link"
                    size="btn-sm"
                    href="https://www.solarinsure.com/battery"
                    target="_blank"
                    className="mr-1.5 !text-primary-800 !inline-block"
                >
                    Learn More
                </Button>
                <span className="text-xs text-gray-500">about our Warranty Solutions & Battery Coverage</span>
            </p>
            <div className="flex justify-end gap-3">
                <Button design="btn-secondary-gray" type="button" onClick={props.onPrevious} processing={processing}>
                    Back
                </Button>
                <Button processing={processing} data-test="warranty-create-step-2-continue-button">
                    Save & Generate Certificate
                </Button>
            </div>
        </div>
    </div>
