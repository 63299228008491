import classnames from 'classnames'
import { HTMLAttributes, ReactNode, useMemo } from 'react'

import { Button } from './Button'
import { IconAlertCircle, IconAlertTriangle, IconCertificate, IconCheckCircle, IconClose } from '../icons'

interface AlertProps extends HTMLAttributes<HTMLDivElement> {
    type?: 'custom' | 'info' | 'default' | 'error' | 'warning' | 'purple' | 'success',
    icon?: ReactNode,
    onClose?: () => void
}

export const Alert = ({
    type = 'default',
    children,
    className,
    icon,
    ...props
}: AlertProps) => {
    const computedIcon = useMemo(() => {
        if (icon) return icon
        switch (type) {
            case 'warning': {
                return <IconAlertTriangle/>
            }
            case 'success': {
                return <IconCheckCircle/>
            }
            case 'purple': {
                return <IconCertificate/>
            }
            default: {
                return <IconAlertCircle/>
            }
        }
    }, [type, icon])

    return <div
        className={classnames('flex gap-3 p-3 justify-between w-full border rounded-lg text-sm items-start lg:items-center', className, {
            'border-primary-300 bg-primary-25 stroke-primary-600text-primary-600': type === 'info',
            'border-error-300 bg-error-25 stroke-error-600 text-error-600': type === 'error',
            'border-warning-300 bg-warning-25 stroke-warning-600 text-warning-600': type === 'warning',
            'border-purple-300 bg-purple-25 stroke-purple-600 text-purple-600': type === 'purple',
            'border-success-300 bg-success-25 stroke-success-600 text-success-600': type === 'success',
            'border-gray-300 bg-gray-25 stroke-gray-600 text-gray-600': type === 'default',
            'border-gray-200': type === 'custom'
        })}
        {...props}
    >
        <div className="flex grow gap-3">
            {computedIcon && <div className="shrink-0">
                {computedIcon}
            </div>}
            <div className="grow">
                {children}
            </div>
        </div>
        {props.onClose && <Button
            design="btn-link-gray"
            onClick={props.onClose}
        >
            <IconClose/>
        </Button>}
    </div>
}
