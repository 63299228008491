import { memo } from 'react'
import { useParams } from 'react-router'

import {
    Button,
    Tabs,
    IconEdit05,
    WarrantyDuplicateAlert,
    WarrantyCancelledBanner,
    WarrantyEditRequestBanner,
    RefreshMonitoringButton,
    WarrantyGoToManufacturerButton,
    WarrantiesCancelOrReinstateButton,
    WarrantyReviewReinstateOrCancelBanner,
    EmailWarrantiesButton,
    DownloadWarrantiesButton
} from '@/components'
import { useAuth } from '@/hooks'
import { CompanyAccountActivityStatus, Warranty } from '@/models'

type WarrantyNavigationProps = {
    warranty: Warranty
    onChange: () => void
}

export const WarrantyNavigation = memo(({
    warranty,
    ...props
}: WarrantyNavigationProps) => {
    const { type, tab = 'warranty' } = useParams()
    const auth = useAuth()

    return <>
        <div className="flex flex-col lg:flex-row justify-between gap-3 mb-6">
            {!warranty.status.isInactive && !auth.user?.company.type.isSoftwareAffiliate &&
                <Tabs
                    className="text-primary-700 text-base font-body"
                    value={tab}
                    tabs={[
                        { to: `/${type}/${warranty.id}/warranty`, id: 'warranty', title: 'Warranty' },
                        { to: `/${type}/${warranty.id}/monitoring`, id: 'monitoring', title: 'Monitoring' },
                        { to: `/${type}/${warranty.id}/system-history`, id: 'system-history', title: 'System History' },
                        { to: `/${type}/${warranty.id}/devices`, id: 'devices', title: 'Device Details' }
                    ]}
                />}
            {!auth.user?.role.isAffiliate &&
                <div className="flex flex-wrap-reverse gap-5 lg:gap-3 justify-end items-end mt-5 lg:mt-0 ml-auto">
                    {tab === 'warranty' && (auth.user?.role.isAdminOrStaff || auth.user?.role.isContractor) && <>
                        <WarrantiesCancelOrReinstateButton
                            selected={[warranty]}
                            onChange={props.onChange}
                        />
                        <Button
                            tooltip={auth.user?.company?.account_activity_status.isDisabled
                                ? CompanyAccountActivityStatus.DISABLED_STATUS_MESSAGE
                                : 'Edit Warranty'}
                            data-test="edit-warranty-button"
                            design="btn-secondary-gray"
                            square
                            href={`/${type}/${warranty.id}/warranty/edit`}
                            tooltipDisabled={warranty.status.isInactive ||
                                (warranty.status.isEditRequested && auth.user?.role.isContractor)}
                            disabled={
                                warranty.status.isInactive ||
                                auth.user?.company?.account_activity_status.isDisabled ||
                                (warranty.status.isEditRequested && auth.user?.role.isContractor)
                            }
                        >
                            <IconEdit05/>
                        </Button>
                        <EmailWarrantiesButton
                            selected={[warranty]}
                            size="btn-md"
                            data-test="email-warranty-button"
                        />
                    </>}
                    <DownloadWarrantiesButton
                        selected={[warranty]}
                        size="btn-md"
                        data-test="download-warranty-button"
                    />

                    {tab === 'monitoring' &&
                        (auth.user?.role.isAdminOrStaff || auth.user?.role.isContractor) &&
                        warranty.homeowner?.system?.status?.refreshable &&
                        <RefreshMonitoringButton
                            warranty={warranty}
                            onChange={props.onChange}
                        />}

                    <WarrantyGoToManufacturerButton
                        warranty={warranty}
                    />
                </div>}
        </div>

        {(warranty.status.isReinstatementRequested || warranty.status.isCancellationRequested) &&
            <WarrantyReviewReinstateOrCancelBanner
                warranty={warranty}
                onChange={props.onChange}
            />}

        {warranty.status.isEditRequested &&
            <WarrantyEditRequestBanner
                warranty={warranty}
                onChange={props.onChange}
            />}

        {warranty.status.isDuplicate &&
            <WarrantyDuplicateAlert/>}

        {warranty.status.isCanceled &&
            <WarrantyCancelledBanner warranty={warranty}/>}
    </>
})
