import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import {
    AddressForm,
    Button,
    Checkbox,
    HandleDuplicatesPopup,
    IconAlertCircle,
    IconHelpCircle,
    Tooltip,
    AddressFormDataType,
    FormErrorsType
} from '@/components'
import { InverterManufacturerEnum, WarrantyProductTypeEnum } from '@/enums'
import { Warranty } from '@/models'
import { IdType, WarrantyDataType, FormChangeEventHandler } from '@/types'

export interface HomeownerAddressFormDataType extends AddressFormDataType {
    duplicate_override?: boolean | 1 | 0
    allow_invalid?: boolean | 1 | 0

    company_id: IdType
    first_name: string
    last_name: string
    email: string
    phone: string
    type: WarrantyProductTypeEnum
    inverter_name: string
    inverter_manufacturer: InverterManufacturerEnum | string
    num_microinverters: number | string
    install_date: Date | null
    system_id: IdType
    panel_name: string
    size_kw: number | string
}

type HomeownerAddressProps = {
    form: HomeownerAddressFormDataType
    onChange: FormChangeEventHandler
    errors: FormErrorsType
    warranty?: Warranty
    className?: string
    required?: boolean
}

export const HomeownerAddressForm = ({
    form,
    errors,
    className,
    required,
    warranty,
    ...props
}: HomeownerAddressProps) => {
    const [isDuplicatePopupOpen, setIsDuplicatePopupOpen] = useState(false)
    const navigate = useNavigate()

    const streetAddressIcon = useMemo(() => {
        if (!errors.address_conflict) return
        return <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
                setIsDuplicatePopupOpen(true)
            }}
        >
            <IconAlertCircle className="stroke-error-500"/>
        </button>
    }, [errors.address_conflict])

    useEffect(() => {
        if (typeof form.duplicate_override === 'undefined' && errors.address_conflict) {
            props.onChange({ target: { name: 'duplicate_override', value: '' } })
        }
        if (typeof form.allow_invalid === 'undefined' && errors.address_invalid) {
            props.onChange({ target: { name: 'allow_invalid', value: '' } })
        }
    }, [errors])

    const handleCancelDuplicate = () => {
        if (warranty) {
            navigate(`/warranties/${warranty.id}`)
        } else {
            navigate('/warranties')
        }
    }

    return <>
        <AddressForm
            form={form}
            onChange={props.onChange}
            errors={{
                ...errors,
                ...errors.address_conflict
                    ? {
                        street_address: <>
                            Address conflict detected. Confirm warranty uniqueness below
                            or <Button
                                type="button"
                                design="btn-link"
                                className="!text-primary-600 hover:underline !inline"
                                onClick={() => setIsDuplicatePopupOpen(true)}
                            >resolve duplicate error</Button>.
                        </>
                    } : {}
            }}
            inputClassName={className}
            streetAddressIcon={streetAddressIcon}
            required={required}
        />

        {typeof form.duplicate_override !== 'undefined' && <Checkbox
            id="allow-duplicate"
            name="duplicate_override"
            label="Confirm Unique Warranty"
            checked={!!form.duplicate_override}
            onChange={props.onChange}
        />}

        {typeof form.allow_invalid !== 'undefined' && <Checkbox
            id="allow-invalid"
            name="allow_invalid"
            label={<span className="flex items-center gap-1">
                Allow Invalid Address
                <Tooltip
                    content="Use this checkbox only when the address verification system is unable to confirm an entered
                     address, which often happens with new-build houses not yet in the postal database. When registering
                      a warranty for a new construction, double-check the accuracy of the address first. If it’s a
                       confirmed valid address, then proceed by checking this box."
                >
                    <IconHelpCircle className="stroke-gray-400"/>
                </Tooltip>
            </span>}
            checked={!!form.allow_invalid}
            onChange={props.onChange}
        />}

        {isDuplicatePopupOpen && <HandleDuplicatesPopup
            cancelButtonText={warranty ? 'Cancel Edit' : undefined}
            isOpen={isDuplicatePopupOpen}
            onConfirm={() => {
                props.onChange({
                    target: {
                        name: 'duplicate_override',
                        value: 1,
                        dataset: { submit: '1' }
                    }
                })
                setIsDuplicatePopupOpen(false)
            }}
            onCancel={handleCancelDuplicate}
            onClose={() => setIsDuplicatePopupOpen(false)}
            warranty={new Warranty({
                id: warranty?.id,
                type: form.type,
                inverter: {
                    model_name: form.inverter_name,
                    provider: form.inverter_manufacturer
                },
                panel: { model_name: form.panel_name },
                size_kw: form.size_kw,
                created_at: warranty?.created_at,
                homeowner: {
                    system_id: form.system_id,
                    company_id: form.company_id,
                    street_address: form.street_address,
                    city: form.city,
                    state: form.state,
                    zip: form.zip,
                    first_name: form.first_name,
                    last_name: form.last_name,
                    email: form.email,
                    phone: form.phone
                }
            } as unknown as WarrantyDataType)}
        />}
    </>
}
