import { DetailedHTMLProps, FormEvent, FormHTMLAttributes, ReactNode } from 'react'

import { useConfirmRouteChange } from '@/hooks'

interface FormProps extends DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
    children: ReactNode
    confirmation?: boolean
    isDirty?: boolean
    onSubmit: () => void
}

export const Form = ({ children, confirmation = false, isDirty = false, ...props }: FormProps) => {
    const confirmationPopup = useConfirmRouteChange(isDirty && confirmation, props.onSubmit)

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        props.onSubmit()
    }

    return <form {...props} onSubmit={handleSubmit}>
        {children}
        {confirmationPopup}
    </form>
}
