import classnames from 'classnames'
import { useMemo, useState } from 'react'
import { flushSync } from 'react-dom'

import {
    AddressInput,
    Button,
    ConfirmPopup,
    Form,
    HandleDuplicatesPopup,
    HandleUnverifiedAddressPopup,
    IconAlertCircle,
    IconCheckCircleBroken,
    IconHelpCircle,
    IconTrash02,
    Input,
    Table,
    Tabs
} from '@/components'
import { FormErrorsType } from '@/components/ui/InputError'
import {
    UploadWarrantiesFormDataType,
    UploadWarrantiesRowDataType,
    UploadWarrantyDataType
} from '@/containers/warranties/Upload'
import { useAnalytics } from '@/hooks'
import { Company, Warranty } from '@/models'
import {
    WarrantyDataType,
    FormChangeEvent,
    FormChangeEventHandler,
    TableRowType,
    WarrantyBulkUploadColumnType
} from '@/types'

type StepValidateDataProps = {
    form: UploadWarrantiesFormDataType
    errors: FormErrorsType
    columns: WarrantyBulkUploadColumnType[]
    onNext: () => void
    onPrev: () => void
    onChange: FormChangeEventHandler
    processing: boolean
    company: Company
}

type TabType = 'all' | 'clean' | 'errored'

const StepValidateData = ({
    form,
    columns,
    errors,
    processing,
    company,
    ...props
}: StepValidateDataProps) => {
    const { trackEvent } = useAnalytics()
    const [tab, setTab] = useState<TabType>('all')
    const [showDuplicatePopup, setShowDuplicatePopup] = useState(false)
    const [showUnverifiedPopup, setShowUnverifiedPopup] = useState(false)
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [selectedRow, setSelectedRow] = useState<UploadWarrantiesRowDataType | null>(null)
    const [focusedCell, setFocusedCell] = useState<{
        index: null | number,
        key: null | string
    }>({
        index: null,
        key: null
    })

    const errorsMemo = useMemo(() => Object.keys(errors).reduce((acc, item) => {
        const [, index, field] = item.split('.')
        return typeof index === 'undefined' ? acc : {
            ...acc,
            [index]: {
                ...acc[parseInt(index)] || {},
                [field]: errors[item]
            }
        }
    }, {} as FormErrorsType[]), [errors])

    const trackHoverEvent = (eventType: string) => {
        const category = 'User Interaction'

        if (eventType === 'bulk_upload_step_3_back') {
            trackEvent('hover_bulk_upload_step_3_back', category, 'Bulk Upload Back Button Hover')
        } else if (eventType === 'bulk_upload_step_3_save') {
            trackEvent('hover_bulk_upload_step_3_save', category, 'Bulk Upload Save Button Hover')
        } else if (eventType === 'email_homeowner_toggle') {
            trackEvent('hover_email_homeowner_toggle', category, 'Bulk Upload Email Homeowner Button Hover')
        } else if (eventType === 'hover_email_homeowner_toggle') {
            trackEvent('hover_email_homeowner_toggle', category, 'Email Homeowner Toggle Hover')
        }
    }

    const handleClickBack = () => {
        props.onPrev()
        trackEvent('click_bulk_upload_step_3_back', 'User Interaction', 'Bulk Upload Step 2 Back Button Click')
    }

    const handleChange = (e: FormChangeEvent) => {
        const clone = [...form.warranties]
        const index = parseInt(e.target.dataset?.index as string)
        const key = e.target.name as keyof UploadWarrantyDataType
        clone[index][key] = e.target.value as string
        props.onChange({ target: { name: 'warranties', value: clone } })
    }

    const handleErrorInfoClick = (row: UploadWarrantiesRowDataType) => {
        setSelectedRow(row)
        const rowErrors = errorsMemo[row.index as number]
        if (rowErrors.address_conflict) {
            setShowDuplicatePopup(true)
        } else if (rowErrors.address_invalid) {
            setShowUnverifiedPopup(true)
        }
    }


    const handleDeleteRow = (index: number) => {
        const payloadClone = [...form.warranties]
        payloadClone.splice(index, 1)
        props.onChange({ target: { name: 'warranties', value: payloadClone } })
        setShowDeletePopup(false)
        setSelectedRow(null)
    }

    const rows = useMemo<TableRowType[]>(() =>
        form.warranties.reduce((acc: TableRowType[], item, index: number) => {
            const rowErrors = errorsMemo[index]
            if (tab === 'clean' && rowErrors) return acc
            if (tab === 'errored' && !rowErrors) return acc
            return [...acc, {
                id: 1 + index,
                action: <Button
                    tooltip="Delete this row"
                    design="btn-link"
                    type="button"
                    onClick={() => {
                        setShowDeletePopup(true)
                        setSelectedRow({ index, ...item })
                    }}
                >
                    <IconTrash02 size="sm" className="stroke-gray-700"/>
                </Button>,
                ...Object.keys(item).reduce((acc, key) => {
                    const error = rowErrors?.[key]
                    const Component = ['street_address', 'address'].includes(key) ? AddressInput : Input
                    return {
                        ...acc,
                        [key]: (focusedCell.index === index && focusedCell.key === key) || error
                            ? <Component
                                id={`row-${1 + index}-${key}-input`}
                                value={item[key as keyof UploadWarrantiesRowDataType] as string}
                                data-index={index.toString()}
                                name={key}
                                onChange={handleChange}
                                errors={!!error}
                                transparent
                                tooltip={error}
                                postIcon={(rowErrors?.address_conflict || rowErrors?.address_invalid) &&
                                    ['street_address', 'address'].includes(key) &&
                                    <Button
                                        tooltip="Click icon to fix this error"
                                        type="button"
                                        design="btn-link"
                                        onClick={() => handleErrorInfoClick({ index, ...item })}
                                    >
                                        <IconHelpCircle size="sm" className="stroke-gray-400"/>
                                    </Button>}
                            />
                            : <button
                                className="px-3.5 py-2.5 border border-transparent w-full text-left cursor-pointer"
                                type="button"
                                onClick={() => {
                                    flushSync(() => setFocusedCell({ index, key }))
                                    const input = document.getElementById(`row-${1 + index}-${key}-input`) as HTMLInputElement
                                    input.focus()
                                }}
                            >
                                {item[key as keyof UploadWarrantiesRowDataType]}
                            </button>
                    }
                }, {})
            }]
        }, []), [form.warranties, errorsMemo, tab, focusedCell])

    return <>
        <Form onSubmit={props.onNext}>
            <p className="text-sm pt-5">
                Our system checks the values of each row for any errors. <br/>
                To edit the data in a cell, click on the cell, make your changes, and then press Enter or click on any
                other cell to apply the modifications.
            </p>

            {!!Object.keys(errorsMemo).length && <p className="text-error-800 text-sm mt-2 mb-8">
                Resolve all errors before uploading the spreadsheet.
            </p>}
            <div className="flex mt-4">
                <Tabs
                    value={tab}
                    onChange={id => setTab(id as TabType)}
                    size="sm"
                    tabs={[
                        { id: 'all', title: `All Rows (${form.warranties.length})` },
                        { id: 'clean', title: `Clean Rows (${form.warranties.length - Object.keys(errorsMemo).length})` },
                        { id: 'errored', title: `Rows with Errors (${Object.keys(errorsMemo).length})` }
                    ]}
                />
            </div>

            {tab === 'errored' && rows.length === 0
                ? <div className="mt-4 text-gray-900 text-sm bg-success-25 border border-gray-200 rounded px-6 py-4 flex flex-col items-center gap-3">
                    <div className="w-14 h-14 bg-success-200 rounded-full flex justify-center items-center border-10 border-success-100">
                        <IconCheckCircleBroken size="lg" className="stroke-success-600"/>
                    </div>

                    <div className="flex flex-col items-center gap-1">
                        <p className="font-semibold">No errors found</p>
                        <p>All good! You have no errors and are ready to upload the data.</p>
                    </div>
                </div>
                : <Table
                    key={tab}
                    className="stripped-table mt-4"
                    options={{
                        sortable: false,
                        useQueryParams: false
                    }}
                    columns={[{
                        field: 'id',
                        title: '#',
                        sticky: true,
                        cellClassName: (row: TableRowType) =>
                            classnames('!px-5 !py-4 border-r', {
                                'bg-error-100 border-error-600 sticky': errorsMemo[row.id as number - 1],
                                'border-gray-200': !errorsMemo[row.id as number - 1]
                            })
                    }, ...columns.filter(({ show }) => show)
                        .map(item => ({
                            field: item.value,
                            title: <div className="px-3.5 py-2.5">{item.title}</div>,
                            className: 'whitespace-nowrap',
                            cellClassName: 'whitespace-nowrap !p-1.5'
                        })), {
                        field: 'action',
                        cellClassName: 'border-l border-gray-200',
                        title: '',
                        sticky: true
                    }]}
                    rows={rows}
                />}

            <div className="flex justify-end gap-3 mt-6">
                <Button
                    design="btn-secondary-gray"
                    onClick={handleClickBack}
                    className="w-36"
                    type="button"
                    onMouseOver={() => trackHoverEvent('bulk_upload_step_3_back')}
                    processing={processing}
                >
                    Back
                </Button>
                <Button
                    onMouseOver={() => trackHoverEvent('bulk_upload_step_3_save')}
                    disabled={!!Object.keys(errorsMemo).length}
                    processing={processing}
                    className="w-36"
                >
                    Save
                </Button>
            </div>
        </Form>
        {selectedRow && showDuplicatePopup && <HandleDuplicatesPopup
            isOpen={showDuplicatePopup}
            cancelButtonText="Remove Duplicate from Upload"
            onConfirm={() => {
                handleChange({
                    target: {
                        name: 'duplicate_override',
                        value: 1,
                        dataset: { index: selectedRow.index?.toString() }
                    }
                })
                setShowDuplicatePopup(false)
            }}
            onCancel={() => {
                handleDeleteRow(selectedRow.index as number)
            }}
            onClose={() => setShowDuplicatePopup(false)}
            warranty={new Warranty({
                homeowner: {
                    company_id: company.id,
                    street_address: selectedRow.street_address,
                    city: selectedRow.city,
                    state: selectedRow.state,
                    zip: selectedRow.zip,
                    first_name: selectedRow.first_name,
                    last_name: selectedRow.last_name,
                    email: selectedRow.email,
                    phone: selectedRow.phone
                }
            } as unknown as WarrantyDataType)}
        />}
        {selectedRow && showUnverifiedPopup && <HandleUnverifiedAddressPopup
            row={selectedRow}
            form={form}
            isOpen={showUnverifiedPopup}
            onChange={props.onChange}
            onClose={() => setShowUnverifiedPopup(false)}
        />}
        {selectedRow && <ConfirmPopup
            isOpen={showDeletePopup}
            cancelButtonText="Cancel Without Changes"
            confirmButtonText="Delete Row"
            className="w-full md:w-auto"
            title={<div className="flex gap-2">
                <IconAlertCircle className="stroke-error-600" size="lg"/>
                Confirm Row Deletion
            </div>}
            description={<p className="flex flex-col gap-4">
                <span>Are you sure you want to delete row #{selectedRow.index as number + 1}?</span>
                <span>
                    This action cannot be undone and will permanently remove all data in this row from your upload.
                </span>
            </p>}
            onConfirm={() => handleDeleteRow(selectedRow.index as number)}
            onCancel={() => {
                setShowDeletePopup(false)
                setSelectedRow(null)
            }}
        />}
    </>
}

export default StepValidateData
