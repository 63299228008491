import classnames from 'classnames'
import { ReactNode, useEffect, KeyboardEvent as ReactKeyboardEvent } from 'react'

import { Button, Card, IconClose } from '@/components'

type PopupPlacement = 'center' |
    'top' | 'bottom' | 'left' | 'right' |
    'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

type PopupProps = {
    children: ReactNode
    placement?: PopupPlacement
    open: boolean
    preventScroll?: boolean
    preserveState?: boolean
    backdrop?: boolean
    backdropStyle?: 'gradient' | 'dark' | 'transparent'
    className?: string
    onClose?: () => void
    'data-test'?: string
}
export const Popup = ({
    children,
    placement = 'center',
    backdrop = true,
    backdropStyle = 'dark',
    preventScroll = true,
    preserveState,
    open,
    'data-test': dataTest,
    ...props
}: PopupProps) => {
    const handleClose = () => {
        if (props.onClose) {
            props.onClose()
        }
    }
    const handleKeyDown = (e: ReactKeyboardEvent<HTMLDivElement> | KeyboardEvent) => {
        if (e.key === 'Escape') {
            handleClose()
        }
    }

    useEffect(() => {
        if (open) {
            document.addEventListener('keydown', handleKeyDown)
        } else {
            document.removeEventListener('keydown', handleKeyDown)
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [open])

    if (!open && !preserveState) return null

    return <div
        className={classnames('fixed w-screen h-screen top-0 left-0 z-50 pointer-events-none flex p-4 animate-fade-in', {
            hidden: preserveState && !open,
            'justify-center': (['center', 'top', 'bottom'] as PopupPlacement[]).includes(placement),
            'items-center': (['center', 'left', 'right'] as PopupPlacement[]).includes(placement),
            'justify-start': (['left', 'top-left', 'bottom-left'] as PopupPlacement[]).includes(placement),
            'justify-end': (['right', 'top-right', 'top-right', 'bottom-right'] as PopupPlacement[]).includes(placement),
            'items-start': (['top', 'top-left', 'top-right'] as PopupPlacement[]).includes(placement),
            'items-end': (['bottom', 'bottom-left', 'bottom-right'] as PopupPlacement[]).includes(placement)
        })}
        data-test={dataTest}
    >
        {preventScroll && open && <style>
            {`body {
                overflow: hidden;
            }`}
        </style>}
        {backdrop && <div
            role="button"
            tabIndex={-1}
            className={classnames('absolute w-screen h-screen top-0 right-0 bottom-0 left-0 visible pointer-events-auto',
                {
                    'bg-black/50': backdropStyle === 'dark',
                    'bg-transparent': backdropStyle === 'transparent',
                    'bg-linear-to-t from-black opacity-40': backdropStyle === 'gradient'
                })}
            onClick={handleClose}
            onKeyDown={handleKeyDown}
        />}
        <Card
            className={classnames('overflow-auto pointer-events-auto max-w-full max-h-full relative',
                props.className)}
        >
            {props.onClose &&
                <div className="absolute right-1 top-1 md:right-4 md:top-4">
                    <Button
                        type="button"
                        design="btn-link"
                        onClick={handleClose}
                        tooltip="Close Pop-up"
                    >
                        <IconClose className="stroke-gray-500"/>
                    </Button>
                </div>}
            <div>
                {children}
            </div>
        </Card>
    </div>
}
