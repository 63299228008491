import classnames from 'classnames'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { Link, useLoaderData, useNavigate, useParams } from 'react-router'

import {
    IconCertificate,
    Button,
    Card,
    HomeownerForm,
    AffiliatesForm,
    BatteriesForm,
    WarrantyHeader,
    IconBarLineChart,
    WarrantyReviewEditRequestPopup,
    WarrantyConfirmEditPopup,
    InverterForm,
    PanelForm,
    PolicyRatesForm,
    DocumentsForm,
    ImagesForm,
    Form,
    FormErrorsType,
    PanelFormDataType,
    InverterFormDataType,
    BatteriesFormDataType,
    HomeownerFormDataType,
    ActionReasonFormDataType,
    PolicyRatesFormDataType,
    AffiliatesFormDataType,
    ImagesFormDataType,
    DocumentsFormDataType,
    WarrantyNavigation,
    HomeownerAddressFormDataType,
    HomeownerAddressForm
} from '@/components'
import { AuthLayout } from '@/containers'
import { InverterManufacturerEnum } from '@/enums'
import { useAuth, useFormData, useToastNotifications } from '@/hooks'
import { Company, Warranty } from '@/models'
import { isApiError } from '@/services'
import { IdType, FormChangeEvent } from '@/types'

export interface WarrantyEditFormType extends
    PanelFormDataType,
    InverterFormDataType,
    BatteriesFormDataType,
    HomeownerFormDataType,
    HomeownerAddressFormDataType,
    ActionReasonFormDataType,
    PolicyRatesFormDataType,
    AffiliatesFormDataType,
    ImagesFormDataType,
    DocumentsFormDataType
{}

const WarrantiesEdit = () => {
    const { type, tab } = useParams()
    const data = useLoaderData() as Warranty
    const auth = useAuth()
    const navigate = useNavigate()
    const { convert } = useFormData()
    const { success, error } = useToastNotifications()
    const [errors, setErrors] = useState<FormErrorsType>({})
    const [processing, setProcessing] = useState(false)
    const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false)
    const [warranty, setWarranty] = useState(data)
    const [newPrice, setNewPrice] = useState(warranty.price)
    const [showFilesUpload, setShowFilesUpload] = useState(false)

    const initialForm: WarrantyEditFormType = {
        type: warranty.type.key,
        company_id: warranty.company?.id as IdType,
        panel_name: warranty.panel?.model_name || '',
        num_panels: warranty.type.hasPowerProduction ? warranty.num_panels || '' : 0,
        panel_wattage: warranty.type.hasPowerProduction ? warranty.panel_wattage || '' : 0,
        size_kw: warranty.type.hasPowerProduction ? warranty.size_kw || '' : 0,

        inverter_name: warranty.inverter?.model_name || '',
        inverter_manufacturer: warranty.inverter?.provider || InverterManufacturerEnum.OTHER,

        num_microinverters: warranty.num_microinverters || '',
        install_date: warranty.homeowner?.install_date as Date,
        system_id: warranty.homeowner?.system_id || '',

        batteries: Array.isArray(warranty.batteries) ? warranty.batteries.map(item => ({
            id: item.id,
            product_id: item.product_id,
            size_kw: item.size_kw,
            serial_number: item.serial_number || '',
            product: {
                id: item.id,
                model_name: item.product.model_name
            }
        })) : [],

        first_name: warranty.homeowner?.first_name || '',
        last_name: warranty.homeowner?.last_name || '',
        email: warranty.homeowner?.email || '',
        phone: warranty.homeowner?.phone || '',

        street_address: warranty.homeowner?.street_address || '',
        city: warranty.homeowner?.city || '',
        state: warranty.homeowner?.state || '',
        zip: warranty.homeowner?.zip || '',

        duplicate_override: (() => {
            if (warranty.homeowner?.allow_duplicate) {
                return 1
            }
            if (warranty.status.isDuplicate) {
                return 0
            }
            return undefined
        })(),

        allow_invalid: warranty.homeowner?.allow_invalid_address ? 1 : undefined,

        affiliate_id: warranty.affiliate_id || '',
        affiliate_name: warranty.affiliate?.name || '',

        policy_rate: warranty.policy_rate,
        battery_rate: warranty.battery_rate,

        edit_reason: '',
        edit_contact_email: ''
    }

    const [form, setForm] = useState({ ...initialForm })

    const isPriceSensitive = useMemo<boolean>(() => Warranty.priceSensitiveFields
        .some(item => !item.compare(form[item.key], initialForm[item.key])), [form])
    const isFormDirty = useMemo<boolean>(() => Object.keys(form)
        .some(key => !Warranty.getField(key).compare(form[key], initialForm[key])), [form])


    const fetchWarranty = useCallback(async () => {
        const res = await Warranty.show(warranty.id) as Warranty
        setWarranty(res)
    }, [warranty])

    const handleChange = (e: FormChangeEvent) => {
        const isChecked = e.target.checked ? 1 : 0
        setForm(form => ({
            ...form,
            [e.target.name]: e.target.type === 'checkbox' ? isChecked : e.target.value
        }))
    }

    const updateWarranty = async () => {
        const formData = convert(form)
        await warranty.update(formData)
        if (auth.user?.role.isContractor && isPriceSensitive && +(warranty.created_at as Date) < +new Date().subtract('day', 1)) {
            success('Warranty edit successfully requested.')
        } else {
            success('Warranty successfully updated.')
        }
        navigate(`/${type}/${warranty.id}/warranty`)
    }

    const validateWarranty = async () => {
        const formData = convert(form)
        await warranty.validate(formData as FormData)
    }

    const fetchNewPrice = async () => {
        const res = await warranty.getCalculatePrices({
            size_kw: form.size_kw,
            policy_rate: form.policy_rate,
            battery_rate: form.battery_rate,
            battery_size_kw: form.batteries
                .reduce((acc: number, { size_kw: sizeKw }) => acc + (parseFloat(sizeKw as string) || 0), 0)
        })
        setNewPrice(res.data.price)
        setIsConfirmationPopupOpen(true)
    }

    const handleSubmit = async () => {
        if (!isFormDirty) {
            navigate(`/${type}/${warranty.id}/warranty`)
            return
        }
        setErrors({})
        setProcessing(true)

        try {
            if (!isConfirmationPopupOpen) {
                await validateWarranty()
                if (isPriceSensitive) {
                    await fetchNewPrice()
                } else {
                    setIsConfirmationPopupOpen(true)
                }
            } else {
                await updateWarranty()
            }
        } catch (err) {
            if (!isApiError(err)) throw err

            error('Something went wrong!')
            if (err.errors) {
                setErrors(err.errors)
                if (!err.errors.edit_reason && !err.errors.edit_contact_email && isConfirmationPopupOpen) {
                    setIsConfirmationPopupOpen(false)
                }
            } else {
                throw err
            }
        } finally {
            setProcessing(false)
        }
    }

    const breadcrumb = useMemo(() => {
        const breadcrumbs: Record<string, [ReactNode, string]> = {
            monitoring: [<IconBarLineChart key={1} className="stroke-gray-500"/>, 'Monitoring'],
            warranties: [<IconCertificate key={1} className="stroke-gray-500"/>, 'Warranties']
        }

        const [icon, label] = type ? breadcrumbs[type] : breadcrumbs.warranties

        return [
            icon,
            <Link key={2} to={`/${type}`}>{label}</Link>,
            <Link key={3} to={`/${type}/${warranty.id}/${tab}`}>Homeowner Details - Warranty Tab</Link>,
            'Edit Warranty'
        ]
    }, [])

    const heading = useMemo(() => <WarrantyHeader
        warranty={warranty}
        backUrl={`/${type}/${warranty.id}/${tab}`}
    />, [warranty])

    return <AuthLayout
        heading={heading}
        breadcrumb={breadcrumb}
    >
        <WarrantyNavigation warranty={warranty} onChange={fetchWarranty}/>

        <Card data-test="warranty-edit-page" className="mb-6">
            <Form onSubmit={handleSubmit} className="flex flex-col gap-6">
                <h2 className="card-title mb-6 flex flex-col lg:flex-row lg:justify-between items-start lg:items-center order-first">
                    <div>
                        <div className="text-xl font-semibold">{warranty.type.brandedTitle}</div>
                        <div className="text-base">Edit Warranty Information</div>
                    </div>
                    <div className="lg:col-span-2 flex flex-col gap-3 lg:flex-row justify-end mt-4 lg:mt-0">
                        <Button design="btn-secondary-gray" hasError href={`/${type}/${warranty.id}/warranty`} data-test="exit-without-saving">
                            Exit Edit Mode Without Saving
                        </Button>
                        <Button processing={processing} data-test="update-warranty-button-1">
                            Exit & Save Edits
                        </Button>
                    </div>
                </h2>

                {warranty.type.hasPowerProduction &&
                    <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 bg-gray-50 shadow-sm p-6">
                        <h3 className="font-semibold lg:col-span-2">Panel Information</h3>
                        <PanelForm
                            form={form}
                            errors={errors}
                            onChange={handleChange}
                        />
                    </div>}

                <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 bg-gray-50 shadow-sm p-6">
                    <div className="lg:col-span-2">
                        <h3 className="font-semibold">
                            Inverter Information {!warranty.type.hasPowerProduction &&
                            <span className="font-normal italic">
                                (Optional - Standalone Inverters Not Covered Under Warranty)
                            </span>}
                        </h3>
                        {!warranty.type.hasPowerProduction && <p className="text-xs text-gray-500 mt-1 leading-6">
                            Providing inverter details
                            is <span className="font-semibold">optional</span> and <span className="font-semibold">not
                            required</span> for <span className="capitalize">{warranty.type.title}</span> warranty
                            registration, as <span className="font-semibold">standalone inverters are not
                            covered</span> under this warranty type. We ask only because if
                            your <span className="font-semibold">battery is connected to a separate standalone
                            inverter</span>, we <span className="font-semibold">may</span> be able to
                            retrieve <span className="font-semibold">basic battery monitoring data</span>  to help
                            expedite claims processing. Solar Insure integrates with select inverters to pull system
                            data, but monitoring is limited to certain brands (listed on the Monitoring tab in
                            Daybreak) and is not guaranteed for all manufacturers on our AVL.
                        </p>}
                    </div>
                    <InverterForm
                        form={form}
                        errors={errors}
                        onChange={handleChange}
                        required={warranty.type.hasPowerProduction}
                        warranty={warranty}
                        productType={warranty.type}
                    />
                </div>

                {warranty.type.hasStorage &&
                    <div className={classnames(
                        'flex flex-col lg:grid lg:grid-cols-2 gap-4 bg-gray-50 shadow-sm p-6',
                        { 'order-first': !warranty.type.hasPowerProduction }
                    )}>
                        <h3 className="font-semibold lg:col-span-2">
                            {warranty.type.coverageTitle}
                        </h3>
                        <BatteriesForm
                            form={form}
                            errors={errors}
                            onChange={handleChange}
                            warranty={warranty}
                            productType={warranty.type}
                            className="lg:col-span-2"
                        />
                    </div>}

                <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 bg-gray-50 shadow-sm p-6">
                    <h3 className="font-semibold lg:col-span-2">Homeowner Information</h3>
                    <HomeownerForm form={form} errors={errors} onChange={handleChange}/>
                </div>

                <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 bg-gray-50 shadow-sm p-6">
                    <h3 className="font-semibold lg:col-span-2">Address</h3>
                    <HomeownerAddressForm
                        form={form}
                        errors={errors}
                        onChange={handleChange}
                        required={true}
                        warranty={warranty}
                    />
                </div>

                {auth.user?.role.isAdminOrStaff && <>
                    <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 bg-gray-50 shadow-sm p-6">
                        <h3 className="font-semibold lg:col-span-2">Assign Affiliate</h3>
                        <AffiliatesForm form={form} errors={errors} onChange={handleChange} warranty={warranty}/>
                    </div>

                    <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 bg-gray-50 shadow-sm p-6">
                        <h3 className="font-semibold lg:col-span-2">Rates Information</h3>
                        <PolicyRatesForm
                            form={form}
                            errors={errors}
                            onChange={handleChange}
                            warranty={warranty}
                            company={warranty.homeowner.company as Company}
                        />
                    </div>
                </>}

                {showFilesUpload ? <div className="flex flex-col lg:grid lg:grid-cols-2 gap-4 bg-gray-50 shadow-sm p-6">
                    <div className="col-span-2">
                        <Button
                            type="button"
                            className="!text-primary-800 self-start"
                            design="btn-link"
                            onClick={() => setShowFilesUpload(false)}
                        >
                            Hide Upload Files
                        </Button>
                    </div>
                    <h3 className="font-semibold lg:col-span-2">Related Documents</h3>
                    <DocumentsForm
                        form={form}
                        errors={errors}
                        onChange={handleChange}
                        inputClassName="col-span-2"
                    />

                    <h3 className="font-semibold lg:col-span-2">Related Images</h3>
                    <ImagesForm
                        form={form}
                        errors={errors}
                        onChange={handleChange}
                        inputClassName="col-span-2"
                    />
                </div> : <Button
                    type="button"
                    className="!text-primary-800 self-start"
                    design="btn-link"
                    onClick={() => setShowFilesUpload(true)}
                >
                    Upload Files
                </Button>}

                <div className="flex lg:flex-row justify-end gap-3">
                    <Button design="btn-secondary-gray" hasError href={`/${type}/${warranty.id}/warranty`}>
                        Exit Edit Mode Without Saving
                    </Button>
                    <Button processing={processing} data-test="update-warranty-button-2">
                        Exit & Save Edits
                    </Button>
                </div>
            </Form>
        </Card>

        <WarrantyConfirmEditPopup
            processing={processing}
            form={form}
            newPrice={newPrice}
            errors={errors}
            isOpen={isConfirmationPopupOpen}
            warranty={warranty}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onClose={() => setIsConfirmationPopupOpen(false)}
        />

        <WarrantyReviewEditRequestPopup
            warranty={warranty}
            onChange={handleChange}
        />
    </AuthLayout>
}

export default WarrantiesEdit
